import googleMeet from "./../Assets/google_meet.svg";
import googleChat from "./../Assets/google_chat.svg";
import googleCalender from "./../Assets/google_calendar.svg";
import googleMail from "./../Assets/google_mail.svg";
import googleDocs from "./../Assets/google_docs.svg";
import googleDrive from "./../Assets/google_drive.svg";
import googleForms from "./../Assets/google_forms.svg";
import googleSheet from "./../Assets/google_sheet.svg";
import googleSlides from "./../Assets/google_slides.svg";

export const LAZYPAY_AUTH_MODAL_VIEWS = {
  OTP: "otp",
  ELIGIBLE: "eligible",
  NONELIGIBLE: "noneligible",
};

export const WORKSPACE_AVAILABLE_GOOGLE_APPS = [
  {
    text1: "Google",
    text2: "Mail",
    imgSrc: googleMail,
    link: "https://mail.google.com/",
    androidAppLink: "com.google.android.gm",
    iosAppLink: "googlegmail://",
    iosAppId: "422689480",
  },
  {
    text1: "Google Drive",
    text2: "30 GB",
    imgSrc: googleDrive,
    link: "https://drive.google.com/drive/",
    androidAppLink: "com.google.android.apps.docs",
    iosAppLink: `googledrive://`,
    iosAppId: "507874739",
  },
  {
    text1: "Google",
    text2: "Meet",
    imgSrc: googleMeet,
    link: "https://meet.google.com/",
    androidAppLink: "com.google.android.apps.meetings",
    iosAppLink: `gmeet://`,
    iosAppId: "1013231476",
  },
  {
    text1: "Google",
    text2: "Chat",
    imgSrc: googleChat,
    link: "https://mail.google.com/chat/",
    androidAppLink: "com.google.android.apps.dynamite",
    iosAppLink: "",
    iosAppId: "1163852619",
  },
  {
    text1: "Google",
    text2: "Calender",
    imgSrc: googleCalender,
    link: "https://calendar.google.com/calendar/",
    androidAppLink: "com.android.calendar",
    iosAppLink: `googlecalendar://`,
    iosAppId: "1108185179",
  },
  {
    text1: "Google",
    text2: "Sheet",
    imgSrc: googleSheet,
    link: "https://docs.google.com/spreadsheets/",
    androidAppLink: "com.google.android.apps.docs.editors.sheets",
    iosAppLink: `googlesheets://`,
    iosAppId: "842849113",
  },
  {
    text1: "Google",
    text2: "Slides",
    imgSrc: googleSlides,
    link: "https://docs.google.com/presentation/",
    androidAppLink: "com.google.android.apps.docs.editors.slides",
    iosAppLink: `googleslides://`,
    iosAppId: "879478102",
  },
  {
    text1: "Google",
    text2: "Forms",
    imgSrc: googleForms,
    link: "https://docs.google.com/forms/",
    androidAppLink: "",
    iosAppLink: "",
    iosAppId: "",
  },
  {
    text1: "Google",
    text2: "Docs",
    imgSrc: googleDocs,
    link: "https://docs.google.com/document/",
    androidAppLink: "com.google.android.apps.docs.editors.docs",
    iosAppLink: `googledocs://`,
    iosAppId: "842842640",
  },
];

export const DIGITAL_SHOWROOM_OS_DATA = {
  androidPackage: "com.digitaldukaan",
  androidAppLink:
    "https://play.google.com/store/apps/details?id=com.digitaldukaan",
  iosLink:
    "https://apps.apple.com/in/app/digital-showroom-by-dotpe/id1544439745",
  iosAppId: "1544439745",
};

export const WORKSPACE_USER_STATUS = {
  IN_PROGRESS: 0, // setup complete but activation not done, same as 2
  ACTIVATED: 1, // activation done
  SUSPENDED: 2, // setup complete but activation not done, same as 0
  EXPIRED: 3,
  PAYMENT_RECEIVED: 4, // no setup initiated
  STUCK_WHILE_SETTING_AUTHENTICATION: 5,
  STUCK_DURING_OFFER_SELECTION: 6,
  STUCK_DUE_TO_CLOUD_IDENTITY_ALREADY_EXISTS: 7,
  STUCK_DURING_CUSTOMER_CREATION: 8,
  STUCK_DURING_CLOUD_IDENTITY_CREATION: 9,
  STUCK_DURING_PASSWORD_UPDATION: 10,
  STUCK_DURING_ENTITLEMENT_CREATION: 11,
  STUCK_WHILE_FETCHING_VERIFICATION_TOKEN: 12,
  STUCK_WHILE_FETCHING_DOMAIN_VERIFICATION_STATUS: 13,
  STUCK_DURING_NEW_USER_CREATION: 14,
  STUCK_DURING_ENTITLEMENT_UPDATION: 15,
  DELETED: 16,
  REFUNDED: 17,
  STATUS_LICENSE_SCHEDULED_FOR_DELETION: 18,
};

export const ADDONS_PURCHASED_KEY = "add-ons-purchased";

export const ROUTES = {
  DOMAIN_EXPLORE: "domain-explore",
  DOMAIN_HISTORY: "domain-history",
  DOMAIN_LIST: "/domain-list",
  DOMAIN_SEARCH: "/domain-search",
  DOMAIN_CONNECT: "/domain-connect",
  DOMAIN_CART: "/domain-cart",
  DOMAIN_OPEN_SEARCH: "/domain-landing/domain-open-search",
  DOMAIN_LANDING_CART: "/domain-landing/domain-landing-cart",
  DOMAIN_PURCHASE_SUCCESS: "/domain-landing/domain-purchase-success",
  DOMAIN_REQUEST_TO_CALLBACK: "/domain-landing/request-to-callback",
  DOMAIN_RENEWAL_DETAILS: "/domain-renewal-details",
  THEME_EXPLORE: "theme-explore",
  THEME_USED: "theme-used",
  THEME_OFFER: "theme-offer",
  WORKSPACE_MANAGER: "workspace-manager",
  WORKSPACE_PURCHASE: "workspace-purchase",
  AD_KEYWORD_SEARCH: `/ad/keyword/search`,
  AD_LOCATION_SEARCH: `/ad/location/search`,
  adStep: (stepNumber) => `/ad/step/${stepNumber}`,
  adHistory: (activeTab) => `/ads-history/${activeTab}`,
  PAYMENT_PROCESS: "payment-process",
  PAYMENT_PROCESSING: "/payment/processing",
  WORKSPACE_PURCHASE: "/workspace-purchase",
  WORKSPACE_MANAGER: "/workspace-manager",
  WORKSPACE_RENEWAL: "/workspace-renewal",
  WORKSPACE_CREATE_ACCOUNT: "/workspace-createaccount",
  WORKSPACE_CREATE_ACCOUNT_AFTER_INITIAL:
    "/workspace-createaccount-after-initial",
  WABA_PAYMENT_INITIATE: "/waba-payment-initiate",
  SUBSCRIPTION_PAYMENT_USING_COUPONS_ROUTE: "/subscription-payment",
  REDIRECT_TO_ORDERS_PAGE: "/redirect/orders",
  SOCIAL_SHARE_PURCHASE_PAGE: "/social-share-purchase",
  SOCIAL_SHARE_PURCHASE_SUCCESS_PAGE: "/social-share-purchase-success",
  PAYMENT: "/payment-mobile",
};

export const WEB_CONSOLE_ROUTES = {
  WABA_MARKETING: "/webview/whatsapp-business-marketing?width=100%",
  ECOMMERCE_SUBSCRIPTION: "/ecommerce-subscription",
  DOMAIN_COMPLETION: "/domain-completion",
  DOMAIN_SALE: "/domain-sale",
  ACTIVE_SUBSCRIPTION_STATUS: "/webview/subscription-status-details",
  PREMIUM_PAGE: "/webview/premium-page",
  ECOMM_PRICING_PAGE: "/webview/ecomm-pricing-page",
};

export const SUBSCRIPTION_TYPE = {
  THEME_ONLY: "premium",
  THEME_PLUS: "theme_plus",
  CUSTOM_THEME: "custom_theme",
};

export const URL_PARAMS = {
  STORE_ID: "storeid",
  TOKEN: "token",
  AUTH_TOKEN: "auth_token",
  APP_VERSION_CODE: "app_version_code", // android
  VERSION_NUMBER: "version_number", // iOS
  CHANNEL: "channel",
  SHOW_INTRO_SEO: "showIntroSeo",
};

export const PREMIUM_PRICING = {
  THEME_ONLY: 2600,
  THEME_PLUS: 5600,
};

export const POLLING_TIMEOUT_DURATION = 300000;

export const WORKSPACE_PLAN_TYPE = {
  WORKSPACE_PLAN_ANNUAL_COMMITMENT: 1,
  WORKSPACE_PLAN_HALF_YEAR_FLEXI: 2,
  WORKSPACE_PLAN_QUARTER_FLEXI: 3,
  WORKSPACE_PLAN_ANNUAL_FLEXI: 4,
  WORKSPACE_PLAN_DOMAIN_UPSELL: 5,
};

/*
  following object contains data about
  the overall workspace status values
*/
export const WORKSPACE_INFO_STATUS = {
  WORKSPACE_STATUS_PAYMENT_RECEIVED: 0,
  WORKSPACE_STATUS_IN_PROGRESS: 1,
  WORKSPACE_STATUS_ACTIVE: 2,
  WORKSPACE_STATUS_EXPIRED: 3,
  WORKSPACE_STATUS_DELETED: 4,
  WORKSPACE_STATUS_PAYMENT_REFUNDED: 5,
  WORKSPACE_STATUS_DUE_FOR_RENEWAL: 6,
  WORKSPACE_RENEWAL_ON_DATE: 7,
  WORKSPACE_SUSPENDED_DUE_TO_DOMAIN_EXPIRY: 8,
  WORKSPACE_ACC_CANCELLED_DUE_TO_TOS_NOT_ACCEPTED: 9,
  ACCOUNT_DUE_TO_LICENSE_CAP_REDUCTION_AFTER_RENEWAL: 10,
};

export const PREMIUM_SUPPORT_NUMBER = " 9240263608";

/** Allowed operations on custom catalog */
export const CUSTOM_CATALOG_OPERATION = {
  ADD: "ADD",
  DELETE: "DELETE",
};

/** CATALOG TYPES that can be mutated in customCatalog Object*/
export const CUSTOM_CATALOG_CATALOGTYPE = {
  COLLECTION: "COLLECTION",
  CATEGORY: "CATEGORY",
  PRODUCT: "PRODUCT",
};

export const ORDER_TYPE = {
  B2B: 0,
  DELIVERY: 1,
  PICKUP: 2,
  SELF_BILLED: 3,
  PAYMENT_LINK: 4,
  SERVICE: 5,
};

export const ORDER_CUSTOMIZATION = {
  ORDER: 0,
  SERVICE: 1,
  SERVICE_WITH_ADDRESS: 2,
  B2B: 3,
};

export const STORE_CUSTOMIZATION = {
  ORDER_BASED: 0,
  SERVICE_BASED_WITH_ADDRESS_DISABLED: 1,
  SERVICE_BASED_WITH_ADDRESS_ENABLED: 2,
  B2B: 3,
};

export const PAYMENT_TYPE_CONSTANTS = {
  EMI: "cemi",
};

export const AUTH_TOKEN_KEY = "token";
export const STORE_ID_KEY = "storeid";
export const DIGITAL_SHOWROOM_URL = {
  STAGE: "https://website.digitaldukaan.app/",
  PROD: "https://digitalshowroom.in/",
};

export const APP_SECTION_DEEPLINK = {
  ECOMMERCE_WEBSITE_SUBSCRIPTION: "https://download.digitalshowroom.app/zSGL",
  BRAND_WEBSITE_SECTION: "https://download.digitalshowroom.app/tcJ7",
  DOMAIN_SECTION: "https://download.digitalshowroom.app/N4tA",
  HOMEPAGE: "https://download.digitalshowroom.app/63Mf",
};

export const SOCIAL_MEDIA_LOGO_URL = {
  TWITTER: "https://cdn.dotpe.in/longtail/themes/76/s5CTn5yg.png",
  INSTA: "https://cdn.dotpe.in/longtail/themes/76/xp0HbCZn.png",
  YOTUBE: "https://cdn.dotpe.in/longtail/themes/76/MKYZFh6E.png",
};
export const SOCIAL_MEDIA_REDIRECT_URL = {
  TWITTER: "https://x.com/digitalshowrmin?s=21",
  INSTA: "https://www.instagram.com/digitalshowroomin?igsh=OGQ5ZDc2ODk2ZA==",
  YOTUBE: "https://www.youtube.com/@DigitalShowroom",
};
export const DOMAIN_PURCHASE = "domain-purchase";

export const TRACKING_EVENTS = {
  searchResults: {
    action: "DLP_SearchResults",
    category: "Search Name",
    label: "Domain results fetched",
  },
  cartApplied: {
    action: "DLP_Apply",
    category: "Add to Cart",
    label: "Domain added to cart",
  },
  otpGenerated: {
    action: "DLP_GetOTP",
    category: "OTP fetched",
    label: "OTP fetched successfully",
  },
  buyClicked: {
    action: "DLP_BuyNow",
    category: "Buy Now clicked",
    label: "Buy Now clicked",
  },
  domainPurchased: {
    action: "DLP_Purchased",
    category: "Domain purachased",
    label: "User purchased domain successfully",
  },
  domainCartSignInLaterClicked: {
    action: "DLP_Signin_later",
    category: "Sign In Later clicked",
    label: "User skips login",
  },
  postPurchaseManageDomainClicked: {
    action: "DLP_manage_DNS",
    category: "Post purchase manage DNS clicked",
    label: "Post Purchase Manege DNS",
  },
  postPurchaseGetWebsiteClicked: {
    action: "DLP_getWebsite",
    category: "Post purchase get website clicked",
    label: "Post Purchase Get Website",
  },
  contactUsClickedFromHeader: {
    action: "DLP_contactus_header",
    category: "Contact Us clicked from header",
    label: "Contact Us from header",
  },
  signInClickedFromHeader: {
    action: "DLP_signin_header",
    category: "Sign In clicked from header",
    label: "Sign In from header",
  },
  cartClickedFromheader: {
    action: "DLP_cart_header",
    category: "Cart clicked from header",
    label: "Cart Redirection from header",
  },
  manageDomainFromHeader: {
    action: "DLP_managedomain_header",
    category: "Manage Domain clicked from header",
    label: "Manage Domain from header",
  },
  manageWebsiteFromHeader: {
    action: "DLP_managewebsite_header",
    category: "Manage Website clicked from header",
    label: "Manage Website from header",
  },
  logoutFromHeader: {
    action: "DLP_logout_header",
    category: "Logout clicked from header",
    label: "Logout from header",
  },
  searchEmptyCart: {
    action: "DLP_search_emptycart",
    category: "Searched from empty cart",
    label: "Searched from empty cart",
  },
  searchFromSearchResult: {
    action: "DLP_search_searchresults",
    category: "Searched from search result page",
    label: "Searched from search result page",
  },
  domainTenureFromCart: {
    action: "DLP_domaintenure_cart",
    category: "domain tenure changed from cart",
    label: "domain tenure changed from cart",
  },
  domainRemoveFromCart: {
    action: "DLP_removedomain_cart",
    category: "domain removed from cart",
    label: "domain removed from cart",
  },
  signInAndPayFromCart: {
    action: "DLP_signin&pay_cart",
    category: "Clicked on checkout CTA",
    label: "Clicked on checkout CTA",
  },
  contactUsPostPurchase: {
    action: "DLP_contactus_postpurhase",
    category: "ContactUs clicked from PostPurchase Page",
    label: "ContactUs from PostPurchase Page",
  },
  otpVerified: {
    action: "DLP_OTPVerified",
    category: "OTP verified",
    label: "OTP verified on add to cart click successfully",
  },
  otpVerifiedHeader: {
    action: "DLP_OTPVerified_Header",
    category: "OTP verified",
    label: "OTP verified from sign in from header successfully",
  },
  otpVerifiedSignIn: {
    action: "DLP_OTPVerified_Signin",
    category: "OTP verified",
    label: "OTP verified from sign and click successfully",
  },
  contactCallback: "Domain_Landing_Page_Callback",
  searchPerformed: "Domain_Landing_Page_Search",
  postPurchaseBuyANewDomain: {
    action: "DLP_buyNewDomain_button",
    category: "Post Purchase Action",
    label: "DLP Post purchase buy a new domain clicked",
  },
  postPurchaseDownloadDSApp: {
    action: "DLP_download_DSapp",
    category: "Post Purchase Action",
    label: "DLP Post purchase Download DS App Button clicked",
  },
};

export const EVENT_TYPE = {
  gaEvent: "GA_EVENT",
  cleverTap: "CLEVER_TAP",
};

export const MOBILE_NUM_REGEX = /^[0-9]{10}$/;

export const DEVICE_TYPE = {
  WINDOW_PHONE: "Windows Phone",
  ANDROID: "Android",
  IOS: "iOS",
  UNKNOWN: "unknown",
};

/*
 * this would be removed later when
 *  new premium page is 100% rolled out on android and ios
 */
export const PREMIUM_PAGE_BACKWARD_COMPATABILITY_APP_VERSION = {
  IOS_APP_VERSION: "2.08",
  ANDROID_APP_VERSION: "4.7.62",
};
export const INDIAN_MOBILE_NO_EXTENSION = "+91";

export const PAYMENT_OPTIONS = {
  VISA: `${process.env.REACT_APP_BASE_CDN_URL}longtail/custom_website/8474898/RM0kyw72.png`,
  AMEX: `${process.env.REACT_APP_BASE_CDN_URL}longtail/custom_website/8474898/rfVV51nc.png`,
  MASTER_CARD: `${process.env.REACT_APP_BASE_CDN_URL}longtail/custom_website/8474898/40Sz71lk.png`,
  UPI: `${process.env.REACT_APP_BASE_CDN_URL}longtail/custom_website/8474898/05XNZSlU.png`,
};
export const DOMAIN_CONNECT = "domain-connect";
export const DOMAIN_PURCHASE_EXTERNAL_SOURCE = "external";
export const DOMAIN_BLOCKED_STATUS = 14;
export const MERCHANT_SOURCE = "digital-showroom";
export const STORE_NAME_CHARACTER_LIMIT = 30;

export const ECOMM = "ecomm";
export const ECOMM_ELITE = "ecomm_elite";
export const ECOMM_PLUS = "ecomm_plus";

export const SUBSCRIPTION_FLAG = {
  NOT_SUBSCRIBED: 0,
  SUBSCRIBED: 1,
  OLD_USER: 2,
  EXPIRED: 3,
};