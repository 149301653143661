import React, { useLayoutEffect, useRef } from "react";
import "./CommonStyles/helpers.scss";
import "./CommonStyles/reset.scss";
import "./CommonStyles/tachyons/index.scss";
import "./CommonStyles/color.scss";
import AppRouter from "./routes";
import AlertFlow from "./Components/AlertFlow";
import VariantModal from "./Components/VariantModal";
import VariantSelectedListModal from "./Components/VariantSelectedListModal";
import VariantChooseRepeatModal from "./Components/VariantChooseRepeatModal";
import ParentProvider from "./parentProvider";
import ErrorHandling from "./errorHandling";
import clevertap from "clevertap-web-sdk";
import DomainRenewalSuccessModal from "./Components/DomainRenewalSuccessModal";
import useAvailablePaymentOptions from "./Hooks/useAvailablePaymentOptions";
import { useSelector } from "react-redux";

export default function AppRoot() {
  const storeDataRef = useRef(null);

  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.data,
  }));

  useAvailablePaymentOptions();
  useLayoutEffect(() => {
    if (!storeDataRef.current && storeData?.store?.store_id) { // so that this only gets called when previous value of storeData is null

      clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
      clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
      clevertap.spa = true;
      clevertap.init(process.env.CLEVERTAP_ID);

      const storeName = storeData?.store?.store_name;
      const phoneNumber = storeData?.store?.owner?.phone || "";

      clevertap?.onUserLogin?.push({
        Site: {
          Name: storeName,
          Identity: phoneNumber
            ? phoneNumber.includes("+91")
              ? phoneNumber
              : `+91${phoneNumber}`
            : "",
          Phone: phoneNumber
            ? phoneNumber.includes("+91")
              ? phoneNumber
              : `+91${phoneNumber}`
            : "",
        },
      });

      storeDataRef.current = storeData;
    }
  }, [storeData?.store?.store_id]);

  return (
    <ErrorHandling>
      <ParentProvider>
        <AppRouter />
        <AlertFlow />
        <VariantModal />
        <VariantSelectedListModal />
        <VariantChooseRepeatModal />
        <DomainRenewalSuccessModal />
      </ParentProvider>
    </ErrorHandling>
  );
}
