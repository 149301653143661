export * from "./regex";
export const DOMAIN = {
  STATUS: {
    ACTIVATED: 6,
    EXPIRED: 9,
    BLOCKED: 14,
    BLOCKED_DUE_TO_STORE_DELETION: 10,
    OUT_OF_GRACE_PERIOD: 11,
    BLOCKED_DUE_TO_STORE_DELETE: 10,
    PAYMENT_RECIEVED: 3,
    DOMAIN_PURCHASED_EXTERNAL_REGISTRAR: 4,
    SSL_GENERATED: 5,
    ISSUE_PURCHASING_DOMAIN_FROM_REGISTRAR: 7,
    DOMAIN_IN_QUEUE: 12,
    DOMAIN_STUCK_IN_QUEUE: 13,
  },
  SOURCE: {
    EXTERNAL: "external",
    RESELLER: "reseller",
  },
  TYPE: {
    TEMPORARY: 0,
    PURCHASED: 1,
    EXTERNAL: 2,
  },
};

export const PAYMENT_METHODS = {
  CARD: "card",
  LAZYPAY: "lazyPay",
  AIRTEL: "airtel",
  PAYTM_UPI: "paytmupi",
  PAYTM: "paytm",
  EMI: "cemi",
};

export const STORE_CUSTOMIZATION = {
  ORDER_BASED: 0,
  SERVICE_BASED_WITH_ADDRESS_DISABLED: 1,
  SERVICE_BASED_WITH_ADDRESS_ENABLED: 2,
  B2B: 3,
};

export const MOBILE_EVENT_TARGET = {
  BOTH: 0,
  ANDROID: 1,
  IOS: 2,
};

export const WEB_CONSOLE_URL = `https://web.${
  process.env.REACT_APP_CUSTOM_ENV === "DEV" ? "digitaldukaan.app" : "dotpe.in"
}`;

export const DOMAIN_CART_PRICES_CONSTANTS = {
  SSL_ENCRYPTION: 499,
  PREMIUM_HOSTING: 499,
};

export const WHATSAPP_CONTACT_US_NUMBER = "7428533884";

export const KYC_STATUS = {
  NOT_STARTED: 0,
  INITIATED: 1,
  FAILED: 2,
  COMPLETED: 3,
};

export const PAYMENT_PURCHASE_TYPE = {
  BRAND_WEBSITE: "brand-website",
};
