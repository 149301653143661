import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError } from "../../Components/Toast";
import { sendDataToApp } from "../../Utils/androidEvents";
import { setPrePaymentData, setPurchaseType } from "../../Utils/paymentHelper";
import { removeLoader, savePrePaymentData, showLoader } from "../Actions";
import apiCall from "../../Services/api";
import {
  GET_PAYMENT_DETAIL_FOR_BRAND_WEBSITE,
  GET_RENEWAL_PAYMENT_DETAILS_BRAND_WEBSITE,
} from "../ActionTypes";
import { PAYMENT_PURCHASE_TYPE } from "../../constants";

function* fetchBrandWebsitePaymentDetails(actions) {
  yield put(showLoader());
  sendDataToApp({
    showLoader: true,
  });
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/premium/storeTheme`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPurchaseType(PAYMENT_PURCHASE_TYPE.BRAND_WEBSITE);
      if (response && response?.data?.data?.is_payable) {
        setPrePaymentData(response?.data?.data?.payment_details);
        yield put(savePrePaymentData(response?.data?.data?.payment_details));
        actions.callback && actions.callback(true);
      } else {
        sendDataToApp({
          refreshToken: true,
        });
        actions.callback && actions.callback(false, response?.data);
      }
    } else {
      ToastNotifyError(response?.data?.message);
    }
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  } catch (err) {
    sendDataToApp({
      stopLoader: true,
    });
    sendDataToApp({
      refreshToken: true,
    });
    yield put(removeLoader());
  }
}

function* fetchBrandWebsiteRenewalPaymentDetails(actions) {
  try {
    yield put(showLoader());
    sendDataToApp({
      showLoader: true,
    });
    const response = yield apiCall({
      method: "POST",
      url: "/dotk/vm1/premium/initiateBrandWebsitePurchase",
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      setPurchaseType(PAYMENT_PURCHASE_TYPE.BRAND_WEBSITE);

      setPrePaymentData(JSON.stringify(response?.data?.data));
      yield put(savePrePaymentData(response?.data?.data));
      actions?.callback?.();
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  } finally {
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  }
}

export default function* root() {
  yield takeEvery(
    GET_PAYMENT_DETAIL_FOR_BRAND_WEBSITE,
    fetchBrandWebsitePaymentDetails
  );
  yield takeEvery(
    GET_RENEWAL_PAYMENT_DETAILS_BRAND_WEBSITE,
    fetchBrandWebsiteRenewalPaymentDetails
  );
}
